import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layouts"


const NotFoundPage = () => {
  return (
    <Layout>
      <section className="w-100 bg-rich-black pos-r h-100">
        <div className="main-wrap mx-auto d-flx flex-dir-col j-c-c pos-r z-depth-3">
          <div className="wrapper-y will-grow j-c-c">
            <div className="wrapper-yt will-grow">
              <div className="maxwidth-mt mx-auto wrapper will-grow ">
                <h1 className="co-white tx-c error-largetext">
                  404
                </h1>
                <p className="maxwidth-sm mx-auto smalltext tx-c co-white my-2 py-1">
                Oops! This is not the page you are looking for.
                </p>
                <p className="tx-c">
                <Link to="/" className="co-magenta"> Return Home</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage
